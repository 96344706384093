
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';
import 'react-tooltip/dist/react-tooltip.css';
import colors from 'tailwindcss/colors';
import { serialKill } from '@elendi/feature-superjson-serial-killer';
import './styles.css';
const App = <T extends {
    session: Session;
}>({ Component, pageProps }: AppProps<T>) => {
    const parsedPageProps = serialKill(pageProps);
    return (<SessionProvider session={pageProps.session}>
      <Head>
        <meta name='viewport' content='width=device-width, user-scalable=no'/>
      </Head>

      <NextNProgress color={colors.red[600]} height={2} showOnShallow={false} options={{ showSpinner: false }}/>

      <ThemeProvider attribute='class'>
        <Component {...parsedPageProps}/>
      </ThemeProvider>
    </SessionProvider>);
};
const __Next_Translate__Page__19505ef908b__ = App;

    export default __appWithI18n(__Next_Translate__Page__19505ef908b__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  